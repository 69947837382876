import { useAppStore } from '~/store/app'

interface SeoParams {
  alias: string;
  content_title: string;
  meta_title: string;
  meta_description: string;
  meta_keyword: string;
  seo_img: string;
}

export default () => {
  const { $pinia, $domainUrl, $device } = useNuxtApp()
  const store = useAppStore($pinia)
  const route = useRoute()
  const page = computed(() => route.query.page ? ` #${route.query.page}` : '')
  const pageLink = computed(() => route.query.page ? `?page=${route.query.page}` : '')
  const seoData = computed(() => {
    const currentPath = route.path || '/'
    return store.seoData?.find(
      (ISeo) => `/${ISeo.alias}` === currentPath ||
        (ISeo.alias === '/' && currentPath === '/') ||
        // Fallback to home page if path not found
        (!store.seoData.some((seo) => `/${seo.alias}` === currentPath) && ISeo.alias === '/')
    )
  })

  const deviceClass = $device.isMobileOrTablet ? 'mobile' : 'desktop'

  useHead({
    bodyAttrs: {
      class: `${deviceClass} swal2-toast-shown`
    }
  })

  const getAlias = (alias: string | undefined) => {
    if (!alias) {
      return ''
    }
    if ((route.path === '/' && alias === '/') || alias === '/') {
      return `${$domainUrl}/`
    }
    return `${$domainUrl}/${alias}`
  }

  const getCanonical = (alias: string | undefined) => {
    if (route.path.startsWith('/the-thao') && route.path !== '/the-thao/lich-thi-dau') {
      return `${$domainUrl}/the-thao`
    }
    if (route.path === '/khuyen-mai/the-loai/thuong-nap') {
      return `${$domainUrl}/khuyen-mai`
    }
    if (route.query.page && Number(route.query.page) > 1) {
      return getAlias(alias) + pageLink.value
    }
    return getAlias(alias)
  }

  useHead({
    title: () => seoData.value?.meta_title + page.value || '',
    meta: [
      // Base
      {
        property: 'og:type',
        content: 'website'
      },
      {
        hid: 'description',
        name: 'description',
        content: () => seoData.value?.meta_description + page.value || ''
      },
      {
        name: 'keywords',
        content: () => seoData.value?.meta_keyword || ''
      },

      // FB
      {
        hid: 'og:url',
        property: 'og:url',
        content: () => getAlias(seoData.value?.alias) + pageLink.value
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: () => seoData.value?.meta_title + page.value || ''
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: () => seoData.value?.meta_description + page.value || ''
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: () => seoData.value?.seo_img || ''
      },

      // Twitter
      {
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'twitter:url',
        content: () => getAlias(seoData.value?.alias) + pageLink.value
      },
      {
        name: 'twitter:title',
        content: () => seoData.value?.meta_title + page.value || ''
      },
      {
        name: 'twitter:description',
        content: () => seoData.value?.meta_description + page.value || ''
      },
      {
        name: 'twitter:image',
        content: () => seoData.value?.seo_img || ''
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: () => getCanonical(seoData.value?.alias)
      }
    ]
  })
}
